export enum AddressField {
  STREETNUMBER = 'street_number',
  ROUTE = 'route',
  LOCALITY = 'locality',
  COUNTRY = 'country',
  POSTALCODE = 'postal_code'
}

export function getAddressField(address: google.maps.places.PlaceResult, key: AddressField) {
  let res = '';
  address.address_components.forEach(function (component) {
    component.types.forEach(function (type) {
      if (type === key) {
        res = component.long_name;
      }
    });
  });
  return res;
}
