import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { StorageTypeGuard } from './guards/storage-type.guard';
import { DebugGuard } from './guards/debug.guard';
import { AppRouting } from './app-routing.module.type';
import { LoginComponent } from './components/login/login.component';
import { ChooseStorageTypeComponent } from './components/choose-storage-type/choose-storage-type.component';
import { DebugComponent } from './components/debug/debug.component';
import { ScanTagComponent } from './components/scan-tag/scan-tag.component';
import { StorageListComponent } from './components/storage-list/storage-list.component';
import { DeliveryOrderComponent } from './components/delivery-order/delivery-order.component';
import { SearchComponent } from './components/search/search.component';
import { ControlComponent } from './components/control/control.component';

const routes: Routes = [
  {path: AppRouting.LOGIN, component: LoginComponent},
  {path: AppRouting.CHOOSESTORAGETYPE, component: ChooseStorageTypeComponent, canActivate: [AuthGuard]},
  {path: AppRouting.DEBUG, component: DebugComponent, canActivate: [AuthGuard, DebugGuard]},
  {path: AppRouting.SCANTAG, component: ScanTagComponent, canActivate: [AuthGuard, StorageTypeGuard]},
  {path: AppRouting.STORAGELIST, component: StorageListComponent, canActivate: [AuthGuard]},
  {path: AppRouting.DELIVERYORDER, component: DeliveryOrderComponent, canActivate: [AuthGuard]},
  {path: AppRouting.SEARCH, component: SearchComponent, canActivate: [AuthGuard]},
  {path: AppRouting.SEARCH + '/:id', component: SearchComponent, canActivate: [AuthGuard]},
  {path: AppRouting.CONTROL, component: ControlComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: AppRouting.CHOOSESTORAGETYPE}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
