export enum AppRouting {
  LOGIN = 'login',
  CHOOSESTORAGETYPE = 'choose-storage-type',
  DEBUG = 'debug',
  SCANTAG = 'scan-tag',
  STORAGELIST = 'storage-list',
  DELIVERYORDER = 'delivery-order',
  SEARCH = 'search',
  CONTROL = 'control',
}
