import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { StorageType } from '../../../lib/lib-shared/types/StorageType';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { AppRouting } from '../../app-routing.module.type';

@Component({
  selector: 'lm-delivery-order',
  templateUrl: './delivery-order.component.html',
  styleUrls: ['./delivery-order.component.scss']
})
export class DeliveryOrderComponent implements OnInit {
  public StorageType = StorageType;
  public AppRouting = AppRouting;

  public filterForm: FormGroup;
  public trackingSheetList = [];
  public orderArticleTrackingList = [];

  constructor(
    private fb: FormBuilder,
    public apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadTrackingSheetList();
  }

  buildForm() {
    this.filterForm = this.fb.group({
      from: '',
      to: '',
      orderId: ''
    });
  }

  loadTrackingSheetList() {
    this.apiLavandierService.getTrackingSheetDetailsList()
      .subscribe(
        (trackingSheetList: Object[]) => this.trackingSheetList = trackingSheetList
      );
  }

  getCsvData() {
    // return this.storageFinishList.map(d => {
    //   return {
    //     orderNumber: d.order.number,
    //     finishAt: d.finishAt,
    //     createdAt: d.createdAt,
    //     quantity: d.quantity,
    //     orderQuantity: d.order.quantity,
    //     orderType: d.order.type,
    //   };
    // });
  }
}
