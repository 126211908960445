import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { IAddressOrder, ICard, IOrder, IUser } from './api.service.type';
import { AuthService } from './auth.service';
import { StringPage } from '../utils/Page';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url = ApiService.generateUrl(environment.api_url);
  private url_public = ApiService.generateUrlPublic(environment.api_url);

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
  }

  static generateUrl(base: string) {
    return `${base}/client`;
  }

  static generateUrlPublic(base: string) {
    return `${base}`;
  }

  setUrl(base: string) {
    this.url = ApiService.generateUrl(base);
    this.url_public = ApiService.generateUrlPublic(base);
  }

  getAddressIsManaged(params: { postalCode: string }) {
    return this.http.get(`${this.url_public}/address/ismanaged`, {
      headers: this.authService.getHttpHeaders(),
      params: new HttpParams({fromObject: params})
    });
  }

  postContact(data: { email: string, subject: string, phoneNumber: string, firstName: string, lastName: string, message: string }) {
    return this.http.post(`${this.url_public}/contact`, data);
  }

  getDeadlineList() {
    return this.http.get(`${this.url_public}/deadline`);
  }

  getShopList() {
    return this.http.get(`${this.url_public}/shop`);
  }

  postUserChangeForgottenPassword(data: { token: string, password: string }) {
    return this.http.post(`${this.url_public}/user/changeforgottenpassword`, data);
  }

  postUserForgottenPassword(data: { email: string }) {
    return this.http.post(`${this.url_public}/user/forgottenpassword`, data);
  }

  postAddress(firstName, lastName, streetNumber, route, locality, country, postalCode, formattedAddress, lat, lng, phoneNumber, additional) {
    const data = {
      firstName: firstName,
      lastName: lastName,
      streetNumber: streetNumber,
      route: route,
      locality: locality,
      country: country,
      postalCode: postalCode,
      formattedAddress: formattedAddress,
      lat: lat,
      lng: lng,
      phoneNumber: phoneNumber,
      additional: additional,
    };
    return this.http.post(`${this.url}/address`, data, this.authService.getHttpOptions());
  }

  putAddress(addressId: number, firstName, lastName, streetNumber, route, locality, country, postalCode, formattedAddress, lat, lng,
             phoneNumber, additional) {
    const data = {
      firstName: firstName,
      lastName: lastName,
      streetNumber: streetNumber,
      route: route,
      locality: locality,
      country: country,
      postalCode: postalCode,
      formattedAddress: formattedAddress,
      lat: lat,
      lng: lng,
      phoneNumber: phoneNumber,
      additional: additional,
    };
    return this.http.put(`${this.url}/address/${addressId}`, data, this.authService.getHttpOptions());
  }

  deleteAddress(addressId: number) {
    return this.http.delete(`${this.url}/address/${addressId}`, this.authService.getHttpOptions());
  }

  postDefaultAddress(addressId: number) {
    const data = {
      addressId: addressId,
    };
    return this.http.post(`${this.url}/address/default`, data, this.authService.getHttpOptions());
  }

  putUserPhoneValidate(params: { phoneCode: string }) {
    return this.http.put(`${this.url}/user/phonevalidate`, params, this.authService.getHttpOptions());
  }

  putUserPhoneCode() {
    return this.http.put(`${this.url}/user/phonecode`, null, this.authService.getHttpOptions());
  }

  putUserDisable(params: { password: string }) {
    return this.http.put(`${this.url}/user/disable`, params, this.authService.getHttpOptions());
  }

  putUser(user: IUser) {
    return this.http.put(`${this.url}/user`, user, this.authService.getHttpOptions());
  }

  postPassword(oldPassword, newPassword) {
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    return this.http.post(`${this.url}/user/password`, data, this.authService.getHttpOptions());
  }

  getUser() {
    return this.http.get(`${this.url}/user`, this.authService.getHttpOptions());
  }

  getTimeSlots() {
    return this.http.get(`${this.url}/timeslot`, this.authService.getHttpOptions());
  }

  getTimeSlotsByAddress(addressId: number) {
    return this.http.get(`${this.url}/timeslot/${addressId}`, this.authService.getHttpOptions());
  }

  postOrderIdDelivery(id, params: { address: IAddressOrder, date: string, start: string, end: string }) {
    return this.http.post(`${this.url}/order/${id}/delivery`, params, this.authService.getHttpOptions());
  }

  postOrder(order: IOrder) {
    return this.http.post(`${this.url}/order`, order, this.authService.getHttpOptions());
  }

  putOrderIdCancel(id) {
    return this.http.put(`${this.url}/order/${id}/cancel`, null, this.authService.getHttpOptions());
  }

  putOrderIdPay(id, data: { cardToken: string }) {
    return this.http.put(`${this.url}/order/${id}/pay`, data, this.authService.getHttpOptions());
  }

  getOrderDetails(id: number) {
    return this.http.get(`${this.url}/order/${id}/details`, this.authService.getHttpOptions());
  }

  getOrder(id: number) {
    return this.http.get(`${this.url}/order/${id}`, this.authService.getHttpOptions());
  }

  getOrderList(params: { temporary?: string, finish?: string, cancel?: string, orderTransportDate?: string }, page?: StringPage) {
    return this.http.get(`${this.url}/order`, {
      headers: this.authService.getHttpHeaders(), params: {...params, ...page}
    });
  }

  getArticleRangeList() {
    return this.http.get(`${this.url}/article/range`, this.authService.getHttpOptions());
  }

  getArticleOptionList() {
    return this.http.get(`${this.url}/article/option`, this.authService.getHttpOptions());
  }

  getDiscountCode(params: { code: string; }) {
    return this.http.get(`${this.url}/discountcode`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  postPaymentCardDelete(data: { cardId: string }) {
    return this.http.post(`${this.url}/payment/card/delete`, data, this.authService.getHttpOptions());
  }

  postPaymentCard(data: ICard) {
    return this.http.post(`${this.url}/payment/card`, data, this.authService.getHttpOptions());
  }

  getPaymentCardList() {
    return this.http.get(`${this.url}/payment/card`, this.authService.getHttpOptions());
  }

  getIncidentTypeList() {
    return this.http.get(`${this.url}/incident/type`, this.authService.getHttpOptions());
  }

  getPdfInvoice(orderId: number) {
    return this.http.get(`${this.url}/pdf/invoice/${orderId}`, {
      headers: this.authService.getHttpHeaders(), responseType: 'blob'
    });
  }

  getCredit() {
    return this.http.get(`${this.url}/credit`, this.authService.getHttpOptions());
  }

  getSponsorship(params: { code: string }) {
    return this.http.get(`${this.url}/sponsorship`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }
}
