import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit {
  public trackingSheetNumberFormControl = new FormControl();
  @ViewChild('trackingSheetNumberInput') trackingSheetNumberInput: ElementRef;

  public refError = false;
  public trackingSheet = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.reset();

    this.trackingSheetNumberFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.refError = false;
          if (ref && ref !== '') {
            this.trackingSheet = null;
            return this.apiLavandierService.getTrackingSheetDetails(ref)
              .pipe(
                catchError(() => {
                  this.refError = true;
                  return of(null);
                })
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(trackingSheet => {
        if (trackingSheet) {
          this.trackingSheet = trackingSheet;
        } else {
          this.reset(true);
        }
      });
  }

  reset(refError = false) {
    this.refError = refError;

    this.trackingSheetNumberFormControl.enable({emitEvent: false});
    this.getTrackingSheetNumberInputFocus();

    if (!refError) {
      this.trackingSheetNumberFormControl.reset();
    }
  }

  getTrackingSheetNumberInputFocus() {
    setTimeout(() => this.trackingSheetNumberInput.nativeElement.focus());
  }
}
