import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbProgressbarConfig, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, map } from 'rxjs/operators';

import { StorageType } from '../../../lib/lib-shared/types/StorageType';
import { FreeStorageModalComponent } from '../modals/free-storage-modal/free-storage-modal.component';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { AppRouting } from '../../app-routing.module.type';

@Component({
  selector: 'lm-storage-list',
  templateUrl: './storage-list.component.html',
  styleUrls: ['./storage-list.component.scss']
})
export class StorageListComponent implements OnInit {
  public StorageType = StorageType;
  public AppRouting = AppRouting;

  @ViewChild(NgbTabset) storageTabSet;

  public filterForm: FormGroup;
  public storageMap = new Map();

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private progressBagConfig: NgbProgressbarConfig,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadStorageMap();
    this.initStorageFilter();
    this.progressBagConfig.max = 100;
    this.progressBagConfig.height = '37px';
  }

  buildForm() {
    this.filterForm = this.fb.group({
      orderId: '',
    });
  }

  loadStorageMap() {
    this.storageMap = new Map();
    this.apiLavandierService.getStorageUsedList()
      .pipe(map((storageList: Object[]) => ListToMap.list(storageList, 'type')))
      .subscribe(
        (storageMap) => this.storageMap = storageMap
      );
  }

  initStorageFilter() {
    this.filterForm.valueChanges
      .pipe(debounceTime(200))
      .subscribe(() => this.loadStorageMap());
  }

  /**
   * A method called to finish a storage
   * @param storage to finish
   */
  onFinishStorage(storage) {
    const freeLocation = this.modalService.open(FreeStorageModalComponent, {backdrop: 'static', centered: true});
    freeLocation.componentInstance.storageLocation = storage.storageLocation.ref;
    freeLocation.result.then((result) => {
      if (result.confirm) {
        // this.apiPtlService.putStorageFinish(storage.id).subscribe(() => { // todo wait api
        //   this.loadStorageMap();
        // });
      }
    });
  }
}
