import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

import { StorageType } from '../../../lib/lib-shared/types/StorageType';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { FreeStorageModalComponent } from '../modals/free-storage-modal/free-storage-modal.component';
import { DebugService } from '../../services/debug.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';

@Component({
  selector: 'lm-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public StorageType = StorageType;

  public orderIdFormControl = new FormControl(null, Validators.required);
  public orderPinFormControl = new FormControl(null, Validators.required);
  public orderArticleTrackingByTypeMap = new Map();
  public orderDetails = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public debugService: DebugService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.orderIdFormControl.setValue(params.id);
        this.searchOrder();
      }
    });
  }

  searchOrder() {
    this.orderDetails = null;
    this.orderArticleTrackingByTypeMap = new Map();
    this.apiLavandierService.getOrderIdOrderArticleTrackingList(this.orderIdFormControl.value)
      .pipe(map((orderArticleTrackingList: Object[]) => ListToMap.list(orderArticleTrackingList, 'flux')))
      .subscribe(
        (orderArticleTrackingMap: Map<number, any>) => {
          this.orderArticleTrackingByTypeMap = orderArticleTrackingMap;
          if (orderArticleTrackingMap.size) {
            this.orderDetails = orderArticleTrackingMap.values().next().value[0].orderArticle.orderBag.order;
          }
        }
      );
  }

  searchOrderNumeroPin() {
    this.orderDetails = null;
    this.orderArticleTrackingByTypeMap = new Map();
    this.apiLavandierService.getOrderByPin(this.orderPinFormControl.value)
      .pipe(map((orderArticleTrackingList: Object[]) => ListToMap.list(orderArticleTrackingList, 'flux')))
      .subscribe(
        (orderArticleTrackingMap: Map<number, any>) => {
          this.orderArticleTrackingByTypeMap = orderArticleTrackingMap;
          if (orderArticleTrackingMap.size) {
            this.orderDetails = orderArticleTrackingMap.values().next().value[0].orderArticle.orderBag.order;
          }
        }
      );
  }

  storageRefListByStorageType(storageType) {
    const storageList = [];
    this.orderArticleTrackingByTypeMap.get(storageType).forEach(orderArticleTracking => {
      if (orderArticleTracking.storage !== null && !storageList.includes(orderArticleTracking.storage.ref)) {
        storageList.push(orderArticleTracking.storage.ref);
      }
    });
    return storageList;
  }

  openTrackingSheetModal(event) {
    event.cellElement.blur(); // Method called to prevent ExpressionChangedAfterItHasBeenCheckedError
    const freeStorageModalComponent = this.modalService.open(FreeStorageModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    freeStorageModalComponent.componentInstance.pdfUrlObservable = this.apiLavandierService.getDocTrackingSheetType(event.row.trackingSheetId, {type: 'pdf'});
  }

  untidied(orderArticleTracking) {
    this.apiLavandierService.putOrderArticleTrackingIdUnTidied(orderArticleTracking.id)
      .subscribe(data => {
        if (!this.errorService.manageError(data)) {
          this.searchOrder();
        }
      });
  }
}
