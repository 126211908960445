import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../../lib/lib-ngx/web-services/auth.service';
import { AppRouting } from '../app-routing.module.type';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private route: Router,
  ) {
  }

  canActivate() {
    if (!this.authService.isLoggedIn()) {
      this.route.navigate(['/' + AppRouting.LOGIN]);
    }
    return this.authService.isLoggedIn();
  }
}
