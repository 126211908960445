import { Injectable } from '@angular/core';

import { StorageType } from '../../lib/lib-shared/types/StorageType';

@Injectable({
  providedIn: 'root'
})
export class StorageTypeService {
  private storageTypeList: StorageType[] = [];

  constructor() {
  }

  public getStorageTypeList(): StorageType[] {
    return this.storageTypeList;
  }

  public setStorageTypeList(storageTypeList: StorageType[]) {
    this.storageTypeList = storageTypeList;
  }

  public reset() {
    this.storageTypeList = [];
  }
}
