import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { FormControl } from '@angular/forms';

import { StorageType } from '../../../lib/lib-shared/types/StorageType';
import { StorageTypeService } from '../../services/storage-type.service';
import { LightService } from '../../services/light.service';
import { AlertService, AlertType } from '../../../lib/lib-ngx/services/alert.service';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ScanStorageModalComponent } from '../modals/scan-storage-modal/scan-storage-modal.component';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { FreeStorageModalComponent } from '../modals/free-storage-modal/free-storage-modal.component';
import { ErrorMessage } from '../../../lib/lib-shared/ErrorMessage';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { ErrorMessageMap } from '../../../lib/lib-ngx/web-services/error';
import { LightState } from '../../../lib/lib-shared/types/LightState';

@Component({
  selector: 'lm-scan-tag',
  templateUrl: './scan-tag.component.html',
  styleUrls: ['./scan-tag.component.scss']
})
export class ScanTagComponent implements OnInit {
  public StorageType = StorageType;

  public tagNumberFormControl = new FormControl();
  @ViewChild('tagNumberInput') tagNumberInput: ElementRef;
  public rangeMap = new Map();
  public userTypeMap = new Map();

  public refError = false;
  private incidentSeen = false;

  constructor(
    private modalService: NgbModal,
    public storageTypeService: StorageTypeService,
    public lightService: LightService,
    public alertService: AlertService,
    public apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.reset();
    this.loadData();
    this.lightService.init()
      .subscribe(success => {
          if (!success) {
            this.alertService.show(AlertType.ERROR, 'Tous les bridges ne sont pas détectés');
          }
        },
        error => this.alertService.show(AlertType.ERROR, 'Tous les bridges ne sont pas détectés'));

    this.tagNumberFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged((prev, curr) => {
          return !this.incidentSeen && prev && curr && prev.name === curr.name;
        }),
        switchMap(ref => {
          this.refError = false;
          this.tagNumberFormControl.disable({emitEvent: false});
          if (ref && ref !== '') {
            return this.apiLavandierService.putOrderArticleTrackingRefStorage({
              incidentSeen: this.incidentSeen,
              ref: ref,
              fluxList: this.storageTypeService.getStorageTypeList()
            })
              .pipe(
                catchError(() => {
                  this.refError = true;
                  return of(null);
                })
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(data => {
        this.incidentSeen = false;
        if (data) {
          if (data.error) {
            if (data.error === ErrorMessage.ORDER_ARTICLETRACKING_REF_NOTFOUND) {
              this.reset(true);
            } else if (data.error === ErrorMessage.ORDER_ARTICLETRACKING_ORDER_INCIDENT) {
              const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
                size: 'xl' as 'lg',
                backdrop: 'static',
                keyboard: false,
                centered: true
              });
              confirmationModal.componentInstance.confirmationDesc = ErrorMessageMap.get(ErrorMessage.ORDER_ARTICLETRACKING_ORDER_INCIDENT);
              confirmationModal.componentInstance.cancelButtonText = 'Article non controlé ❌';
              confirmationModal.componentInstance.validateButtonText = 'Article déjà controlé ✅';
              confirmationModal.result.then(res => {
                if (res) {
                  this.incidentSeen = true;
                  this.tagNumberFormControl.updateValueAndValidity();
                } else {
                  this.reset();
                }
              });
            } else {
              this.errorService.manageError(data);
            }
          } else {
            this.lightService.setState(data.storage.lightId, LightState.ON).subscribe();
            const scanStorageModal = this.modalService.open(ScanStorageModalComponent, {
              size: 'xl' as 'lg',
              backdrop: 'static',
              keyboard: false,
              centered: true
            });
            scanStorageModal.componentInstance.storageData = data;
            scanStorageModal.componentInstance.rangeMap = this.rangeMap;
            scanStorageModal.componentInstance.userTypeMap = this.userTypeMap;
            scanStorageModal.result.then((forceFreeStorage: boolean) => {
              this.apiLavandierService.putOrderArticleTrackingIdTidied(data.id, {forceFreeStorage: forceFreeStorage})
                .subscribe((trackingSheet: any) => {
                  if (trackingSheet) {
                    this.lightService.setState(data.storage.lightId, LightState.ALERT).subscribe();
                    const freeStorageModalComponent = this.modalService.open(FreeStorageModalComponent, {
                      size: 'xl' as 'lg',
                      backdrop: 'static',
                      keyboard: false,
                      centered: true,
                    });
                    freeStorageModalComponent.componentInstance.storageData = data;
                    freeStorageModalComponent.componentInstance.pdfUrlObservable = this.apiLavandierService.getDocTrackingSheetType(trackingSheet.id, {type: 'pdf'});
                    freeStorageModalComponent.componentInstance.trackingSheet = trackingSheet;
                    freeStorageModalComponent.componentInstance.userTypeMap = this.userTypeMap;
                    freeStorageModalComponent.result.then(resultFree => {
                      this.lightService.setState(data.storage.lightId, LightState.OFF).subscribe();
                      this.reset();
                    });
                  } else {
                    this.lightService.setState(data.storage.lightId, LightState.OFF).subscribe();
                    this.reset();
                  }
                });
            });

          }
        } else {
          this.reset();
        }
      });
  }

  reset(refError = false) {
    this.refError = refError;

    this.tagNumberFormControl.enable({emitEvent: false});
    this.getTagNumberInputFocus();

    if (!refError) {
      this.tagNumberFormControl.reset();
    }
  }

  getTagNumberInputFocus() {
    setTimeout(() => this.tagNumberInput.nativeElement.focus());
  }

  loadData() {
    forkJoin([
      this.apiLavandierService.getArticleRangeList(),
      this.apiLavandierService.getUserTypeList(),
    ])
      .pipe(map(([rangeList, userTypeList]: [any[], any[]]) => {
        return [
          ListToMap.convert(rangeList),
          ListToMap.convert(userTypeList),
        ];
      }))
      .subscribe(([rangeMap, userTypeMap]) => {
        this.rangeMap = rangeMap;
        this.userTypeMap = userTypeMap;
      });
  }
}
