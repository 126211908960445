import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { DebugService } from '../services/debug.service';
import { AppRouting } from '../app-routing.module.type';

@Injectable({
  providedIn: 'root'
})
export class DebugGuard implements CanActivate {
  constructor(
    private debugService: DebugService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.debugService.isLoggedIn()) {
      this.router.navigate(['/' + AppRouting.CHOOSESTORAGETYPE]);
      return false;
    }
    return true;
  }
}
