import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageTypeService } from '../services/storage-type.service';
import { AppRouting } from '../app-routing.module.type';

@Injectable({
  providedIn: 'root'
})
export class StorageTypeGuard implements CanActivate {
  constructor(
    private storageTypeService: StorageTypeService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.storageTypeService.getStorageTypeList().length === 0) {
      this.router.navigate(['/' + AppRouting.CHOOSESTORAGETYPE]);
      return false;
    }
    return true;
  }
}
