import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StorageType } from '../../../lib/lib-shared/types/StorageType';
import { StorageTypeService } from '../../services/storage-type.service';
import { AppRouting } from '../../app-routing.module.type';

@Component({
  selector: 'lm-choose-storage-type',
  templateUrl: './choose-storage-type.component.html',
  styleUrls: ['./choose-storage-type.component.scss']
})
export class ChooseStorageTypeComponent implements OnInit {
  public StorageType = StorageType;

  constructor(
    private storageTypeService: StorageTypeService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.storageTypeService.reset();
  }

  onStorageTypeSelected(storageTypeList: StorageType[]) {
    this.storageTypeService.setStorageTypeList(storageTypeList);
    this.router.navigate(['/' + AppRouting.SCANTAG]);
  }
}
