import { Injectable } from '@angular/core';

import { ErrorMessageMap } from '../web-services/error';
import { ErrorMessage } from '../../lib-shared/ErrorMessage';
import { Error, ErrorMap } from '../utils/error';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private generateModal: (text) => any = (text: String) => null;

  constructor() {
  }

  manageError(body) {
    const res = this.hasError(body);
    if (res) {
      this.showModal(body.error);
    }
    return res;
  }

  hasError(body): ErrorMessage | null {
    if (body && body.hasOwnProperty('error')) {
      return body.error;
    }
    return null;
  }

  setModal(generateModal) {
    this.generateModal = generateModal;
  }

  showModal(error: ErrorMessage | Error) {
    let str: string = error as string;
    if (ErrorMessageMap.has(error as ErrorMessage) && ErrorMessageMap.get(error as ErrorMessage) !== '') {
      str = ErrorMessageMap.get(error as ErrorMessage);
    } else if (ErrorMap.has(error as Error) && ErrorMap.get(error as Error) !== '') {
      str = ErrorMap.get(error as Error);
    }
    return this.generateModal(str);
  }
}
