import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../../lib/lib-ngx/web-services/auth.service';
import { AlertService } from '../../../lib/lib-ngx/services/alert.service';
import { DebugService } from '../../services/debug.service';
import { AppRouting } from '../../app-routing.module.type';

@Component({
  selector: 'lm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public AppRouting = AppRouting;

  public isCollapsed = true;
  public debugPasswordFormControl = new FormControl('');
  public currentRoute = '';

  constructor(
    public authService: AuthService,
    private router: Router,
    public alertService: AlertService,
    private modalService: NgbModal,
    public debugService: DebugService,
  ) {
    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.currentRoute = route.url;
      }
    });
  }

  ngOnInit() {
  }

  disconnected() {
    this.authService.logout();
    this.isCollapsed = true;
    this.router.navigate(['/' + AppRouting.LOGIN]);
  }

  onCloseAlert() {
    this.alertService.hide();
  }

  /**
   * A method called to open a modal to log in debug mode
   * @param debugPasswordModal Modal to open
   */
  openDebugModal(debugPasswordModal) {
    this.isCollapsed = true;
    if (this.debugService.isLoggedIn()) {
      this.router.navigate(['/' + AppRouting.DEBUG]);
    } else {
      this.modalService.open(debugPasswordModal);
    }
  }

  /**
   * A method called to logout of debug mode
   */
  onDebugLogout() {
    this.debugService.reset();
    this.isCollapsed = true;
    this.router.navigate(['/' + AppRouting.CHOOSESTORAGETYPE]);
  }

  /**
   * A method called when the user validate the password and navigate to debug page
   */
  onValidateDebugPassword() {
    this.debugService.setPassword(this.debugPasswordFormControl.value);
    this.router.navigate(['/' + AppRouting.DEBUG]);
  }
}
