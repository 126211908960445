import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private password = '';

  constructor() {
  }

  public setPassword(password) {
    this.password = password;
  }

  public isLoggedIn() {
    return this.password === 'adminadmin';
  }

  public reset() {
    this.password = '';
  }
}
