import { Component, OnInit } from '@angular/core';
import { concatMap, delay, map } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, from, of } from 'rxjs';

import { LightService } from '../../services/light.service';
import { AlertService, AlertType } from '../../../lib/lib-ngx/services/alert.service';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { LightState } from '../../../lib/lib-shared/types/LightState';

@Component({
  selector: 'lm-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {
  public LightState = LightState;
  public bridgeList = [];
  public lightMap = new Map();

  constructor(
    private lightService: LightService,
    private alertService: AlertService,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.lightService.init(true)
      .subscribe(success => {
          if (!success) {
            this.alertService.show(AlertType.ERROR, 'Tous les bridges ne sont pas détectés');
          } else {
            this.loadBridgeList();
            this.loadLightMap();
          }
        },
        error => this.alertService.show(AlertType.ERROR, 'Tous les bridges ne sont pas détectés'));
  }

  /**
   * A method called to get the bridge list
   */
  loadBridgeList() {
    this.apiLavandierService.getLightBridgeList()
      .subscribe((bridgeList: Object[]) => this.bridgeList = bridgeList);
  }

  /**
   * A method called to get the light list
   */
  loadLightMap() {
    this.apiLavandierService.getLightDetailsList()
      .pipe(map((data: Object[]) => ListToMap.list(data, 'bridgeId')))
      .subscribe((lightMap: Map<number, Object>) => this.lightMap = lightMap);
  }

  setAllState(state: LightState) {
    const observableListList = [];
    for (const bridge of this.bridgeList) {
      for (const index of Object.keys(this.lightMap.get(bridge.id))) {
        if (!observableListList[index]) {
          observableListList[index] = [];
        }
        observableListList[index].push(this.lightService.setState(this.lightMap.get(bridge.id)[index].id, state));
      }
    }

    return from(observableListList)
      .pipe(concatMap(v => concat(of(v), EMPTY.pipe(delay(1000)))))
      .subscribe(observableList => forkJoin(observableList).subscribe());
  }

  /**
   * A method called to set a state to a light with his id
   * @param light Light to set state
   * @param state State to set
   */
  setState(light, state: LightState) {
    this.lightService.setState(light.id, state)
      .subscribe(() => {
        this.loadBridgeList();
        this.loadLightMap();
      });
  }
}
