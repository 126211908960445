import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { StorageTypeService } from '../../../services/storage-type.service';
import { StorageType } from '../../../../lib/lib-shared/types/StorageType';

@Component({
  selector: 'lm-scan-storage-modal',
  templateUrl: './scan-storage-modal.component.html',
  styleUrls: ['./scan-storage-modal.component.scss']
})
export class ScanStorageModalComponent implements OnInit {
  public StorageType = StorageType;

  @ViewChild('storageNumberInput') public storageNumberInput: ElementRef;
  public storageData = null;
  public rangeMap = new Map();
  public userTypeMap = new Map();
  public storageRefFormControl = new FormControl(null);

  constructor(
    public storageTypeService: StorageTypeService,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.getStorageNumberInputFocus();

    this.storageRefFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          if (ref && ref !== '') {
            return of(ref);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(ref => {
        if (ref) {
          if (ref === this.storageData.storage.ref) {
            this.activeModal.close(false);
          } else if (ref === this.storageData.storage.ref + '-FULL') {
            this.activeModal.close(true);
          }
        }
      });
  }

  getStorageNumberInputFocus() {
    setTimeout(() => this.storageNumberInput.nativeElement.focus());
  }
}
