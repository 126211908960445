import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { ErrorMessage } from '../../../../lib/lib-shared/ErrorMessage';

@Component({
  selector: 'lm-scan-tracking-sheet',
  templateUrl: './scan-tracking-sheet.component.html',
  styleUrls: ['./scan-tracking-sheet.component.scss']
})
export class ScanTrackingSheetComponent implements OnInit {

  @Input() trackingSheet = null;
  @Output('focus') focus = new EventEmitter<boolean>();

  public storageNumberFormControl = new FormControl();
  @ViewChild('storageNumberInput') public storageNumberInput: ElementRef;

  public storageError = false;
  public orderArticleTrackingScanStatusMap = new Map();

  constructor(
    public apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.trackingSheet.orderArticleTrackingList.forEach(orderArticleTracking =>
      this.orderArticleTrackingScanStatusMap.set(orderArticleTracking.id, orderArticleTracking.factoryAt ? !orderArticleTracking.factoryMissing : undefined));
    this.reset();

    if (this.allArticlesScan()) {
      setTimeout(() => {
        this.errorService.showModal(ErrorMessage.ORDER_ARTICLETRACKING_ALREADY_FACTORY);
        this.storageNumberFormControl.disable({emitEvent: false});
        this.focus.emit(true);
      });
    }

    this.storageNumberFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.storageError = false;
          this.storageNumberFormControl.disable({emitEvent: false});
          if (ref && ref !== '') {
            if (this.trackingSheet.orderArticleTrackingList.some(v => v.ref === ref)) {
              const orderArticleTracking = this.trackingSheet.orderArticleTrackingList.find(v => v.ref === ref);
              return this.apiLavandierService.putOrderArticleTrackingIdFactory(orderArticleTracking.id, {factoryMissing: false})
                .pipe(map(data => {
                  if (!this.errorService.manageError(data)) {
                    this.orderArticleTrackingScanStatusMap.set(orderArticleTracking.id, true);
                    return data;
                  }
                  return null;
                }));
            } else {
              const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg', keyboard: false});
              confirmationModal.componentInstance.confirmationDesc = 'Cet article n\'appartient pas à cette tracking sheet';
              confirmationModal.componentInstance.cancelButtonText = 'Fermer';
              confirmationModal.result
                .then(() => this.reset()).catch(() => this.reset());
              return of(null);
            }
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(data => {
        if (this.allArticlesScan()) {
          this.focus.emit(true);
        } else {
          this.reset();
        }
      });
  }

  missingArticle(orderArticleTracking) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg', keyboard: false});
    confirmationModal.componentInstance.confirmationDesc = 'Êtes vous sur de vouloir marquer cet article comme manquant ?';
    confirmationModal.componentInstance.validateButtonText = 'Valider';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';
    confirmationModal.result.then((result) => {
      if (result) {
        this.apiLavandierService.putOrderArticleTrackingIdFactory(orderArticleTracking.id, {factoryMissing: true})
          .subscribe((data) => {
            if (!this.errorService.manageError(data)) {
              this.orderArticleTrackingScanStatusMap.set(orderArticleTracking.id, false);
              if (this.allArticlesScan()) {
                this.storageNumberFormControl.disable({emitEvent: false});
                this.focus.emit(true);
              } else {
                this.reset();
              }
            } else {
              this.reset();
            }
          });
      }
    }).catch(error => null);
  }

  allArticlesScan() {
    let isAllScan = true;
    this.orderArticleTrackingScanStatusMap.forEach(value => {
      if (value === undefined) {
        isAllScan = false;
      }
    });
    return isAllScan;
  }

  reset(reset = true) {
    this.storageNumberFormControl.enable({emitEvent: false});
    this.getStorageNumberInputFocus();

    if (reset) {
      this.storageNumberFormControl.reset();
    }
  }

  getStorageNumberInputFocus() {
    setTimeout(() => this.storageNumberInput.nativeElement.focus());
  }
}
